import {
  Button,
  Divider,
  Header,
  Icon,
  List,
  Segment,
} from "semantic-ui-react";
import "./style.css";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Contact from "../Contact/Contact";

const Home = () => {
  const [modalOpen, setModal] = useState(false);
  const [top, setTop] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [isHeaderLogo, setHeaderLogo] = useState(false);
  useEffect(() => {
    AOS.init();
  });
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    handleResize();
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setHeaderLogo(true);
    } else {
      setHeaderLogo(false);
    }
    if (window.scrollY > 0) {
      setTop(false);
    } else {
      setTop(true);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
      setSidebar(false);
    }
  };

  const handleGoLinkedin = () => {
    window.open(
      "https://www.linkedin.com/company/paperairplane-media/",
      "_blank"
    );
  };

  const handleScrollToSection = (section) => {
    setSidebar(false);
    const element = document.getElementById(section);
    //you can do it by jquery. no matter
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const MenuItems = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: sidebar ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: sidebar ? "-100px" : "",
        }}
      >
        <div
          style={{
            marginRight: !sidebar ? "20px" : "",
            marginBottom: sidebar ? "20px" : "",
          }}
        >
          <Header
            as={sidebar ? "h2" : "h3"}
            className="grow"
            inverted
            content="Innovations"
            onClick={() => handleScrollToSection("products")}
          />
        </div>
        {/* <div
          style={{
            marginRight: !sidebar ? "20px" : "",
            marginBottom: sidebar ? "20px" : "",
          }}
        >
          <Header
            as={sidebar ? "h2" : "h3"}
            className="grow"
            inverted
            content="About Us"
            onClick={() => handleScrollToSection("about")}
          />
        </div> */}
        <div
          style={{
            marginRight: !sidebar ? "20px" : "",
            marginBottom: sidebar ? "20px" : "",
          }}
        >
          <Header
            as={sidebar ? "h2" : "h3"}
            className="grow"
            inverted
            content="In The Press"
            onClick={() => handleScrollToSection("press")}
          />
        </div>
        <div
          style={{
            marginRight: !sidebar ? "20px" : "",
            marginBottom: sidebar ? "20px" : "",
          }}
        >
          <Header
            as={sidebar ? "h2" : "h3"}
            className="grow"
            inverted
            content="Our Team"
            onClick={() => handleScrollToSection("team")}
          />
        </div>

        <div
          style={{
            marginRight: !sidebar ? "20px" : "",
            marginBottom: sidebar ? "20px" : "",
          }}
        >
          <Header
            as={sidebar ? "h2" : "h3"}
            className="grow"
            inverted
            content="Contact"
            onClick={() => setModal(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className="animate__animated animate__fadeIn animate__slower"
      id="home-container"
    >
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundImage: "url(./images/home-hero-1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="wrap-section"
      >
        <div
          id="header"
          style={{
            width: "100%",
            height: "100px",
            position: "fixed",
            top: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: top ? "rgba(0,0,0,0.0)" : "rgba(0,0,0,0.9)",
            // transition: "background-color 0.1s ease; box-shadow 0.1s ease;",
            boxShadow: top ? "" : "0px 0px 16px 3px rgba(0,0,0,0.75)",
            zIndex: 4,
          }}
        >
          <div
            style={{
              padding: "10px",
              opacity: isHeaderLogo ? 1 : 0,
              transition: "opacity ease-in-out 0.5s",
            }}
          >
            <a href="/">
              <img src="./images/logo.png" width="150x" />
            </a>
          </div>
          {!mobile && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MenuItems />
              <div style={{ marginRight: "20px" }}>
                <Icon
                  className="icon-button"
                  // style={{ cursor: "pointer" }}
                  name="linkedin"
                  inverted
                  size="big"
                  onClick={handleGoLinkedin}
                />
              </div>
            </div>
          )}
          {mobile && (
            <div style={{ marginRight: "20px" }}>
              <Icon
                className="icon-button"
                // style={{ cursor: "pointer" }}
                name={sidebar ? "close" : "bars"}
                inverted
                size="big"
                onClick={handleSidebar}
              />
            </div>
          )}
          {mobile && (
            <div
              style={{
                opacity: sidebar ? 1 : 0,
                zIndex: 3,
                backgroundColor: "rgba(0,0,0,0.9)",
                position: "fixed",
                top: "100px",
                width: "100%",
                height: sidebar ? "100vh" : "0px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                transition: "height ease-in-out 0.2s",
              }}
            >
              <MenuItems />
            </div>
          )}
        </div>

        <div style={{ width: "100%" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
            className="flex-section"
          >
            {/* <div
              style={{
                maxWidth: "800px",
                minWidth: "365px",
                // marginLeft: "100px",
                flex: 1,
              }}
              className="mobile-margin"
            >
              <Header
                as="h1"
                inverted
                style={{
                  fontSize: "3.2em",
                  textAlign: "center",
                  // textShadow: "1px 1px 10px #000000",
                }}
              >
                PaperAirplane
                <Header.Subheader style={{ fontSize: ".6em" }}>
                  <p>
                    A First-of-its-Kind Cinema Marketing Agency serving Studios,
                    Content Providers, Exhibitors and Marketplace Partners.
                  </p>
                  <p>Where Innovation Takes Flight.</p>
                </Header.Subheader>
              </Header>
            </div> */}
            <div
              style={{
                maxWidth: "800px",
                minWidth: "365px",
                // marginLeft: "100px",
                textAlign: "center",
                flex: 1,
              }}
              className="mobile-margin"
            >
              <img src="./images/logo.png" width="300px" />
              <Header
                as="h1"
                inverted
                style={{
                  fontSize: "3.2em",
                  textAlign: "center",
                  // textShadow: "1px 1px 10px #000000",
                }}
              >
                <Header.Subheader style={{ fontSize: ".6em" }}>
                  <p>
                    A First-of-its-Kind Cinema Marketing Agency serving Studios,
                    Content Providers, Exhibitors and Marketplace Partners.
                  </p>
                  <p>Where Innovation Takes Flight.</p>
                </Header.Subheader>
              </Header>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          flexDirection: "column",
        }}
      >
        {/* <Header as="h1" style={{ fontSize: "2.2em" }}>
          Friends and Partners
        </Header> */}
        <div className="slide-container">
          <div className="slider">
            <div className="slide-track">
              <div className="slide">
                <img src="./images/carousel/amc-logo.jpg" height="100" alt="" />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/warnerbros-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/universal-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/imax-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/sony-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/cinemark-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/lionsgate-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/disney-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/cinemacon-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>

              <div className="slide">
                <img
                  src="./images/carousel/barco-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/kingdom-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/fathom-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/regal-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/focus-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/harkins-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img src="./images/carousel/a24-logo.jpg" height="100" alt="" />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/marcus-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/nato-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/showcase-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/roadside-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/neon-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/paramount-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/reald-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/briarcliff-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/trafalgar-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/vertical-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img src="./images/carousel/bb-logo.jpg" height="100" alt="" />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/gkids-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/carousel/bleecker-logo.jpg"
                  height="100"
                  alt=""
                />
              </div>
              <div className="slide">
                <img src="./images/carousel/mgm-logo.jpg" height="100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 50px)",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundColor: "#F1F1F1",
          backgroundImage: "url(./images/home-hero-2.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          data-aos="fade-up"
          className="wrap-section"
          style={{ width: "100%", display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
            className="flex-section"
          >
            <div
              style={{ width: "555px", margin: "50px" }}
              className="mobile-margin"
            >
              <Header
                data-aos="fade-up"
                as="h1"
                style={{
                  fontSize: "2.6em",
                  color: "#F1F1F1",
                  textAlign: "center",
                }}
              >
                PaperAirplane is the industry’s trusted partner, with over 40
                studio and cinema clients, working daily with more than 98% of
                domestic exhibitors.
              </Header>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="flex-section"
          >
            <div
              style={{ width: "555px", margin: "50px" }}
              className="mobile-margin"
            >
              <List
                data-aos="fade-up"
                bulleted
                style={{
                  fontSize: "2em",
                  color: "#F1F1F1",
                  // textAlign: "center",
                }}
              >
                <List.Item>Asset Management</List.Item>
                <List.Item>Asset Creation</List.Item>
                <List.Item>Campaign Management</List.Item>
                <List.Item>Media Buying Across Exhibitor Platforms</List.Item>
                <List.Item>Group Sales Program Management</List.Item>
              </List>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "350px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          flexDirection: "column",
        }}
        data-aos="fade-up"
      >
        <Header as="h1" style={{ fontSize: "2.2em" }}>
          Recent Projects
        </Header>
        <div className="slide-container">
          <div className="poster-slider">
            <div className="slide-track">
              <div className="slide">
                <img
                  src="./images/posters/boys-in-the-boat.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/color-purple.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/godzilla-minus-one.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/reagan.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-boy-and-the-heron.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-long-game.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/land-of-bad.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/jesus-revolution.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-apprentice.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/my-hero.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-substance.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/radical.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/wick4.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/songbird.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/saw-x.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/sound-of-freedom.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-chosen.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/unsung-hero.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/young-woman-and-the-sea.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/color-purple.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/godzilla-minus-one.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/jesus-revolution.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/den-of-thieves-2.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-last-showgirl.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/radical.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/saw-x.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/sound-of-freedom.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-chosen.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/unsung-hero.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/young-woman-and-the-sea.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/boys-in-the-boat.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/color-purple.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/godzilla-minus-one.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/jesus-revolution.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/ordinary-angels.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/plane.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/radical.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/saw-x.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/sound-of-freedom.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/the-chosen.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/unsung-hero.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/young-woman-and-the-sea.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
              <div className="slide">
                <img
                  src="./images/posters/boys-in-the-boat.jpg"
                  height="250"
                  width="167"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="products"
        style={{
          width: "100%",
          minHeight: "calc(100vh - 50px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F1F1",
          flexDirection: "column",
          padding: "30px",
          scrollMarginTop: "100px",
        }}
      >
        <div data-aos="fade-up">
          <Header style={{ fontSize: "3.2em" }}>Innovations</Header>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className="wrap-section"
          data-aos="fade-up"
        >
          <div className="flex-section" style={{ flex: 1 }}>
            <div
              style={{
                maxWidth: "500px",
                margin: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobile-margin"
            >
              <img
                data-aos="fade-up"
                src="./images/the-hangar-logo.png"
                width="200px"
                height="105px"
              />
              <Header
                style={{ fontSize: "1.6em", textAlign: "center" }}
                data-aos="fade-up"
              >
                A revolutionary and proprietary Digital Asset Manager designed
                to efficiently deliver digital marketing assets of all types to
                Exhibitors worldwide, empowering clients with seamless asset
                distribution and tracking capabilities.
                <Header.Subheader>
                  <Divider />
                  <List
                    data-aos="fade-up"
                    bulleted
                    style={{ fontSize: "1.4em", textAlign: "left" }}
                  >
                    <List.Item>Over 1,500 Titles Hosted to Date</List.Item>
                    <List.Item>Over 100,000 Assets Hosted to Date</List.Item>
                    <List.Item>Almost 1 Million Downloads to Date</List.Item>
                    <List.Item>Over 3,500 Users and Growing!</List.Item>
                  </List>
                </Header.Subheader>
              </Header>
            </div>
          </div>
          <Divider horizontal section />
          <div className="flex-section" style={{ flex: 1 }}>
            <div
              style={{
                maxWidth: "500px",
                margin: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobile-margin"
            >
              <img
                data-aos="fade-up"
                src="./images/the-tower-logo.png"
                width="200px"
                height="105px"
              />
              <Header
                style={{ fontSize: "1.6em", textAlign: "center" }}
                data-aos="fade-up"
              >
                A powerful and groundbreaking Group Sales management tool that
                simplifies the group sales process, seamlessly connecting
                Exhibitors and Studios, allowing for increased visibility and
                for more sales to be closed.
                <Header.Subheader>
                  <Divider />
                  <List
                    data-aos="fade-up"
                    bulleted
                    style={{ fontSize: "1.4em", textAlign: "left" }}
                  >
                    <List.Item>Manages Group Sales Leads</List.Item>
                    <List.Item>Tracks Box Office</List.Item>
                    <List.Item>
                      High-Quality Customizable Forms and Integrations
                    </List.Item>
                    <List.Item>
                      Collects Data for Outreach and Retargeting
                    </List.Item>
                  </List>
                </Header.Subheader>
              </Header>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="about"
        style={{
          width: "100%",
          height: "calc(100vh - 50px)",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          backgroundColor: "#F1F1F1",
          backgroundImage: "url(./images/home-hero-5.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          scrollMarginTop: "100px",
        }}
      >
        <div
          data-aos="fade-up"
          className="wrap-section"
          style={{ width: "100%", display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
            className="flex-section"
          >
            <div
              style={{ width: "555px", margin: "50px" }}
              className="mobile-margin"
            >
              <Header
                inverted
                as="h1"
                data-aos="fade-up"
                style={{
                  fontSize: "3.2em",
                  textAlign: "center",
                }}
              >
                About Us
                <Header.Subheader
                  style={{ textAlign: "center", fontSize: "1.2rem" }}
                >
                  <p data-aos="fade-up">
                    Co-founders Mike Polydoros and Will Preuss possess extensive
                    experience in cinema marketing, having spearheaded
                    in-theatre campaigns for iconic franchises like THE HUNGER
                    GAMES, THE EXPENDABLES, SAW, TYLER PERRY and JOHN WICK, as
                    well as acclaimed box office hits such as WONDER, LA LA LAND
                    and KNIVES OUT. PaperAirplane builds upon this success,
                    developing tools and strategies that reach moviegoers and
                    drive box office success. Since launching in 2020,
                    PaperAirplane has created and implemented in-theatre
                    campaigns for numerous films, including FREELANCE, RADICAL,
                    THE LONG GAME, HYPNOTIC and the Academy Award winning
                    GODZILLA MINUS ONE. Key members of the PaperAirplane flight
                    crew includeindustry veteran Meggie Isom, formerly of AMC
                    Theatres and Cinema Scene, alongside Amanda Rufener, who
                    brings over a decade of experience from Lionsgate.
                    Completing the team is Laura Beth Boley, fresh from WTA
                    Media, and Nick Polydoros, an invaluable asset to
                    PaperAirplane for the past two years.
                  </p>
                </Header.Subheader>
              </Header>
            </div>
          </div>
        </div>
      </div> */}

      <div
        id="press"
        style={{
          width: "100%",
          // height: "calc(100vh - 50px)",
          minHeight: "calc(100vh - 50px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F1F1F1",
          backgroundImage: "url(./images/home-hero-4.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          flexDirection: "column",
          padding: "30px",
          scrollMarginTop: "100px",
        }}
      >
        <div data-aos="fade-up">
          <Header
            inverted
            style={{ fontSize: "3.2em", textAlign: "center", padding: "50px" }}
            data-aos="fade-up"
          >
            In The Press
          </Header>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className="wrap-section"
          data-aos="fade-up"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              maxWidth: "800px",
            }}
          >
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",

                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://deadline.com/2024/09/box-office-paperairplane-media-1236099271"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/deadline3.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",

                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://deadline.com/2023/07/box-office-paperairplane-mike-polydoros-will-preuss-1235429455"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/deadline.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://www.wsj.com/articles/minions-brings-families-back-to-movie-theaters-11656869821"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/journal.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://www.boxofficepro.com/geneva-convention-2023-innovator-of-the-year-paperairplane-co-founders-mike-polydoros-and-will-preuss/"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/boxoffice.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://www.cnbc.com/2023/07/15/billion-dollar-movies-studio-slump.html"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/cnbc.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://www.nytimes.com/2023/05/31/business/movie-theater-upgrade-pandemic.html"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/times.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <a
                target="_blank"
                href="https://deadline.com/2022/01/paperairplane-theatrical-marketing-firm-launches-mike-polydoros-will-preuss-lionsgate-vets-1234919406/"
                rel="noreferrer"
              >
                <img
                  className="grow"
                  width="100%"
                  src="./images/press/deadline2.jpg"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="letter"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
                maxWidth: "800px",
                margin: "20px",
              }}
            >
              <img width="100%" src="./images/press/nbc.jpg" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="team"
        style={{
          width: "100%",
          minHeight: "calc(100vh - 50px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000000",
          flexDirection: "column",
          padding: "30px",
          scrollMarginTop: "100px",
        }}
      >
        <div data-aos="fade-up">
          <Header
            inverted
            style={{ fontSize: "3.2em", textAlign: "center" }}
            data-aos="fade-up"
          >
            Our Team
            <Header.Subheader
              style={{
                maxWidth: "800px",
                textAlign: "center",
                padding: "20px",
                fontSize: "1.2rem",
              }}
            >
              <p data-aos="fade-up">
                Co-founders Mike Polydoros and Will Preuss possess extensive
                experience in cinema marketing, having spearheaded in-theatre
                campaigns for hundreds of films, including such iconic
                franchises as THE HUNGER GAMES, THE EXPENDABLES, SAW, TYLER
                PERRY and JOHN WICK, as well as acclaimed box office hits such
                as WONDER, LA LA LAND and KNIVES OUT. PaperAirplane builds upon
                this success, developing tools and strategies that reach
                moviegoers and drive box office success. Since launching in
                2020, PaperAirplane has created and implemented in-theatre
                campaigns for numerous films, including FREELANCE, RADICAL, THE
                LONG GAME, HYPNOTIC and the Academy Award winning GODZILLA MINUS
                ONE. Key members of the PaperAirplane flight crew include
                industry veteran Meggie Isom, formerly of AMC Theatres and
                Cinema Scene, alongside Amanda Rufener, who brings over a decade
                of experience from Lionsgate. Completing the team is Laura Beth
                Boley, fresh from WTA Media, and Nick Polydoros, an invaluable
                asset to PaperAirplane since joining the team in 2022.
              </p>
            </Header.Subheader>
          </Header>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className="wrap-section"
          data-aos="fade-up"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              maxWidth: "1400px",
            }}
          >
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/Mike.png" />
              <Header inverted content="Mike Polydoros" />
            </div>
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/Will.png" />
              <Header inverted content="Will Preuss" />
            </div>
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/Meggie.png" />
              <Header inverted content="Meggie Isom" />
            </div>
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/Amanda.png" />
              <Header inverted content="Amanda Rufener" />
            </div>
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/LB.png" />
              <Header inverted content="Laura Beth Boley" />
            </div>
            <div
              data-aos="fade-up"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <img width="200px" src="./images/team/Nick.png" />
              <Header inverted content="Nick Polydoros" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="footer"
        style={{
          backgroundColor: "#000000",
          height: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <span style={{ color: "#FFFFFF", fontSize: "12px" }}>
          &copy; 2024 PaperAirMedia. All Rights Reserved.
        </span>
      </div>
      {modalOpen && <Contact setModal={setModal} />}
    </div>
  );
};

export default Home;
